.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out, margin-bottom 200ms ease-in-out;

  margin-bottom: -0.45rem;

  background: rgba(0, 0, 0, 0.15);
  z-index: 1001;
}

.ReactModal__Overlay--after-open {
  opacity: 1;

  margin-bottom: 0;
}

.ReactModal__Overlay--before-close {
  opacity: 0;

  margin-bottom: -0.45rem;
}
